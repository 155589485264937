<script setup>
    import { usePageEnter } from '#imports';
    import { onMounted } from 'vue';

    import { PreloaderComplete, UserReady } from '~/api/constants/messages';
    import MainScene from '~/components/gl/MainScene.vue';
    import PageWrapper from '~/components/PageWrapper.vue';
    import { useWindowMessage } from '~/composables/useWindowMessage';
    import { useFanatics } from '~/providers/FanaticsProvider';
    import { GlobalAssets } from '~/providers/GlobalAssets';

    const { sendMessage } = useWindowMessage();
    const fanatics = useFanatics();

    onMounted(() => {
        sendMessage(UserReady);
    });

    usePageEnter(() => {
        sendMessage(PreloaderComplete);

        fanatics.ready.value = true;
    });
</script>

<template>
    <PageWrapper>
        <GlobalAssets>
            <MainScene />
        </GlobalAssets>
    </PageWrapper>
</template>

<style lang="scss" scoped>
    @import '@/styles/shared.scss';

    .page {
        @include fill;
        @include flexCenter;
        pointer-events: none;
    }
</style>
